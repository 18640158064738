import { render, staticRenderFns } from "./ka-user-cv-show-page.vue?vue&type=template&id=4b9f3b6c&scoped=true&"
import script from "./ka-user-cv-show-page.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-show-page.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-show-page.vue?vue&type=style&index=0&id=4b9f3b6c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9f3b6c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KIcon from '@web/components/core/k-icon'
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {KButton,KIcon,VContainer,VLayout,VMain,VProgressCircular})
