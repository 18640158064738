<template>
  <div>
    <ka-navbar-cv :is-generating-cv="pdfStatus.isLoading" @generate-pdf="generatePdf" />

    <v-main v-if="state === 'loading'" class="page page--transparent">
      <v-container>
        <v-layout column class="layout-center">
          <v-progress-circular indeterminate size="32"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-main>

    <v-main v-else-if="state === 'protected-page'" class="page page--transparent">
      <ka-user-cv-protected-page @token-changed="loadCv" />
    </v-main>

    <v-main v-else-if="state === 'error'" class="page page--transparent">
      <v-container>
        <v-layout column class="layout-center">
          <h3>{{ stateMessage }}</h3>
          <k-button class="mt-4" :to="{ name: 'Home' }" color="primary">{{ $t('navigation.home') }}</k-button>
        </v-layout>
      </v-container>
    </v-main>

    <div v-else-if="state === 'success'" class="page">
      <div id="ready"><!-- DO NOT REMOVE --></div>
      <header class="header">
        <div class="page-container">
          <div v-if="shouldShowLogo" class="tw-flex tw-place-items-center tw-justify-end">
            <img :src="koderiaCvLogo" alt="Koderia" width="180px" />
          </div>

          <div class="tw-flex tw-place-items-center tw-justify-between">
            <div class="tw-flex tw-items-center">
              <div v-if="!!data.avatar" class="avatar tw-mr-10">
                <img :src="data.avatar" class="avatar__image" />
              </div>

              <div>
                <h1 class="header__title">{{ data.firstName }} {{ data.lastName }}</h1>

                <p v-if="hasDisplayableProfession" class="main__profession">
                  {{ data.profession.displayString }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="page-container page-container--cv">
        <main class="main">
          <div class="mobile-centered">
            <p class="main__bio tw-text-left">{{ data.bio }}</p>

            <div class="main__contact">
              <a :href="`tel:${data.phone}`" class="contact-item tw-whitespace-no-wrap" v-show="data.phone">
                <span>
                    <k-icon name="phone" size="18" />
                </span>
                {{ data.phone }}
              </a>

              <a :href="`mailto:${data.email}`" class="contact-item tw-whitespace-no-wrap" v-show="data.email">
                <span>
                    <k-icon name="paper_plane" size="14" />
                </span>
                {{ data.email }}
              </a>

              <a :href="`${userWeb}`" class="contact-item" v-show="data.web" target="_blank" rel="opener">
                <span>
                    <k-icon name="link" size="16" />
                </span>
                {{ data.web }}
              </a>
            </div>
          </div>

          <hr class="main__hr" />

          <!--<div class="main-radar" v-if="shouldShowChart">
            <div class="main-radar__texchnologies">
              <div class="main-radar__texchnologies-item" v-for="(tech, idx) in topExpertises" :key="idx">
                <k-chip appearance="secondary" class="tw-uppercase">
                  {{ tech.name }}
                </k-chip>

                <span v-if="tech.yearsOfExperience" class="main-radar__texchnologies-item-years">
                  {{ $t(`global.expertiseLevel.${ tech.yearsOfExperience }`) }}
                </span>
              </div>
            </div>

            <div class="main-radar__radar">
              <div class="ka-radar-wrapper">
                <ka-radar-wrapper :cv="data" :size="320" />
              </div>
            </div>
          </div>

          <div class="main__info" v-else>
            <div
              class="main__technologies"
              :class="{
                'main__technologies&#45;&#45;3': topExpertisesLength < 4,
                'main__technologies&#45;&#45;6': topExpertisesLength > 3 && topExpertisesLength < 7
              }"
              v-show="topExpertisesLength > 0"
            >
              <div class="main__technologies-item" v-for="(tech, idx) in topExpertises" :key="idx" v-show="idx < 9">
                <k-chip appearance="secondary" class="tw-uppercase">
                  <span :title="tech.name">
                    {{ tech.name }}
                  </span>
                </k-chip>
                <span v-if="tech.yearsOfExperience" class="main-radar__texchnologies-item-years">
                  {{ $t(`global.expertiseLevel.${ tech.yearsOfExperience }`) }}
                </span>
              </div>
            </div>
          </div>-->

          <section class="section" v-if="data.expertises.length">
            <h4 class="section__title">{{ $t('profile.technologies') }}</h4>
            <div class="section__content" style="width: 100%;">
              <ka-user-cv-show-page-experience :language="appLocale" :data="data.expertises" :is-print="isPrint" />
            </div>
          </section>

          <hr class="main__hr main__hr--large-space" />

          <section class="section section--first" v-if="data.employments.length">
            <h4 class="section__title">{{ $t('global.employment') }}</h4>
            <div class="section__content">
              <div v-for="(employment, idx) in data.employments" :key="idx">
                <ka-user-cv-show-page-employment :language="appLocale" :data="employment" :is-print="isPrint" />
              </div>
            </div>
          </section>

          <section class="section" v-if="data.educations.length">
            <h4 class="section__title">{{ $t('profile.education') }}</h4>
            <div class="section__content">
              <div v-for="(education, idx) in data.educations" :key="idx">
                <ka-user-cv-show-page-education :language="appLocale" :data="education" />
              </div>
            </div>
          </section>

          <section class="section" v-if="data.references.length">
            <h4 class="section__title">{{ $t('profile.references') }}</h4>
            <div class="section__content">
              <div v-for="(reference, idx) in data.references" :key="idx" class="break-inside-avoid">
                <ka-user-cv-show-page-reference :data="reference" />
              </div>
            </div>
          </section>

          <section class="section" v-if="data.certificates.length">
            <h4 class="section__title">{{ $t('profile.certificates') }}</h4>
            <div class="section__content">
              <div v-for="(certificate, idx) in data.certificates" :key="idx" class="break-inside-avoid">
                <ka-user-cv-show-page-certificate :data="certificate" />
              </div>
            </div>
          </section>

          <section class="section" style="min-height: 60px" v-if="data.languages.length">
            <h4 class="section__title section__title--language">{{ $t('profile.languages') }}</h4>
            <div class="section__content section__content-item--language">
              <div v-for="(language, idx) in data.languages" :key="idx" class="break-inside-avoid">
                <ka-user-cv-show-page-language :data="language" :cv-language="appLocale" />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
// import KaRadarWrapper from '../components/ka-radar/radar-wrapper'
import KaNavbarCv from '@web/components/ka-navbar-cv'
import KaUserCvProtectedPage from './ka-user-cv-protected-page'
import KaUserCvShowPageLanguage from './ka-user-cv-show-page-language'
import KaUserCvShowPageReference from './ka-user-cv-show-page-reference'
import KaUserCvShowPageEducation from './ka-user-cv-show-page-education'
import KaUserCvShowPageEmployment from './ka-user-cv-show-page-employment'
import KaUserCvShowPageExperience from './ka-user-cv-show-page-experience'
import KaUserCvShowPageCertificate from './ka-user-cv-show-page-certificate'
import { getRouteLocale } from '@web/constants/language'
import KoderiaLogo from './assets/cv-logo.png'
import { mapGetters } from 'vuex'
import { fetchOtherUserCv, fetchSampleCv } from '@web/api/other-user-api'

export default {
  components: {
    KaNavbarCv,
    // KaRadarWrapper,
    KaUserCvProtectedPage,
    KaUserCvShowPageLanguage,
    KaUserCvShowPageReference,
    KaUserCvShowPageEducation,
    KaUserCvShowPageEmployment,
    KaUserCvShowPageExperience,
    KaUserCvShowPageCertificate
  },
  created() {
    document.body.style.backgroundColor = '#f8f8fb'

    const feedbackEl = document.getElementById('_hj_feedback_container')
    const feedbackEl2 = document.getElementById('_hjRemoteVarsFrame')

    if (feedbackEl) {
      feedbackEl.remove()
    }
    if (feedbackEl2) {
      feedbackEl2.remove()
    }

    this.$store.dispatch('APPBAR_STYLE', 'transparent-dark')

    window.addEventListener('beforeprint', this.changePrint, true)
    window.addEventListener('afterprint', this.changePrint, true)
  },
  data() {
    return {
      state: 'loading',
      stateMessage: null,
      data: null,
      cvSettings: null,
      pdfUrl: null,
      isPrint: false,
      koderiaCvLogo: KoderiaLogo,
      isCvOfSignedUser: false
    }
  },
  computed: {
    ...mapGetters({
      isUserSignedIn: 'AUTH/getIsUserSignedIn'
    }),
    pdfStatus() {
      return this.$store.getters['USER/getStatus']('generatePdfStatus')
    },
    cvId() {
      return this.$route.params.id
    },
    token() {
      return this.$route.query.token
    },
    pToken() {
      return this.$route.query.p
    },
    profileStatus() {
      return this.$store.getters['USER/getStatus']('profileStatus')
    },
    appLocale() {
      return this.$store.getters.appLocale
    },
    locale() {
      return getRouteLocale()
    },
    shouldShowLogo() {
      const showLogoParam = this.$route.query.logo

      return showLogoParam === 'on'
    },
    /*topExpertisesLength() {
      return this.topExpertises ? Object.keys(this.topExpertises).length : 0
    },
    topExpertises() {
      const valueMap = {
        '0-1': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5-10': 5,
        '10+': 6
      }

      return this.data.expertises
        .toSorted((a, b) => {
          const aModifier = this.calcExpertiseModifier(a) || 0.1
          const bModifier = this.calcExpertiseModifier(b) || 0.1

          const aMappedValue = (valueMap[a.yearsOfExperience] || 0) * aModifier
          const bMappedValue = (valueMap[b.yearsOfExperience] || 0) * bModifier

          return bMappedValue - aMappedValue
        })
        .slice(0, 9)
    },*/
    userWeb() {
      const { web } = this.data || {}

      if (web) {
        if (String(web).startsWith('http://') || String(web).startsWith('https://')) {
          return web
        } else {
          return `//${ web }`
        }
      }
      return null
    },
    hasDisplayableProfession() {
      return !['OTHER', 'NON_IT'].includes(this.data?.profession?.value)
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.changePrint, true)
    window.removeEventListener('afterprint', this.changePrint, true)
  },
  watch: {
    profileStatus: {
      immediate: true,
      handler(profileStatus) {
        if (!this.isUserSignedIn) {
          this.loadCv()

          return
        }

        if (!profileStatus.isSuccess) {
          return
        }

        this.isCvOfSignedUser = this.$store.getters['USER/getUserProfile'].id === this.cvId

        this.loadCv()
      }
    }
  },
  methods: {
    changePrint({ type }) {
      this.isPrint = type === 'beforeprint'
    },
    async loadCv() {
      if (this.isCvOfSignedUser) {
        this.data = this.$store.getters['USER/getUserProfile']
        this.cvSettings = this.$store.getters['USER/getCvSettings']
        this.state = 'success'

        return
      }

      try {
        if (this.cvId === 'sample') {
          const { profile, cvSettings } = await fetchSampleCv()

          this.data = profile
          this.cvSettings = cvSettings
        } else {
          const { profile, cvSettings } = await fetchOtherUserCv(this.cvId, this.pToken, this.token)

          this.data = profile
          this.cvSettings = cvSettings
        }

        this.state = 'success'
      } catch (e) {
        if (e.response.status === 403) {
          this.state = 'protected-page'

          return
        }

        if (e.response.status === 404) {
          this.$router.push({ name: 'Page404', params: { locale: this.locale } })

          return
        }

        this.stateMessage = e.response.data
        this.state = 'error'
      }
    },
    /*calcExpertiseModifier(expertise) {
      const fullExpertiseInfo = this.expertises.find(exp => exp.expertiseId === expertise.expertiseId)

      if (!fullExpertiseInfo) {
        return 0
      }

      return fullExpertiseInfo.radarInfos.reduce((accumulator, currentValue) => {
        accumulator += currentValue.weight || 0
        return accumulator
      }, 0)
    },*/
    generatePdf() {
      this.$store.dispatch('USER/generatePdf')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep #kodAppBar,
::v-deep * #kodAppBar {
  @media print {
    display: none;
  }
}

@page {
  margin-top: 2cm;
  margin-bottom: 2cm;
}

@page :first {
  margin-top: 0;
  margin-bottom: 2cm;
}

.page,
.page ::v-deep * {
  -webkit-print-color-adjust: exact;
}

.page {
  color: $koderia-gray-800;
  background-color: $koderia-white;
  min-height: 100vh;
  padding-bottom: 10rem;

  @media print {
    padding-bottom: 0;
  }
}

.page--transparent {
  background-color: transparent;
}

.layout-center {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
}

.page-container {
  width: 100%;
  box-sizing: border-box;
  max-width: 62rem; // 960 + 16 + 16
  padding: 0 1rem;
  margin: 0 auto;

  @media print {
    padding: 0 4rem;
  }
}

.header {
  background-color: $koderia-gray-100;
  padding-top: 6rem;
  padding-bottom: 3rem;

  @include -lg {
    padding-top: 3rem;
  }
}

.header__title {
  font-size: 3em;
  line-height: 1.25;
  font-weight: 600;
  color: $koderia-gray-800;

  @media print {
    padding-top: 0;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 567px) {
    font-size: 1.5rem;
  }
}

.main__profession {
  font-size: 1.25rem;
  line-height: 1.75;
  margin-top: 0.5rem;
  color: $koderia-gray-500;
}

.avatar {
  box-sizing: border-box;
  border-radius: 9999rem;
  overflow: hidden;
  background-color: $koderia-white;
  width: 10rem;
  height: 10rem;

  @include -lg {
    position: static;
    width: 6rem;
    height: 6rem;
    float: unset;
    clear: both;
    right: 1rem;
  }
}

.avatar__image {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mobile-centered {
  text-align: unset;
  padding-top: 2rem;
  color: $koderia-gray-800;

  @include -lg {
    text-align: center;
  }
}

.page-container--cv {
  @media screen {
    min-height: 100vh !important;
  }
}

.main__title {
  position: relative;
  display: none;
  margin-top: 1.5rem;

  @include -lg {
    margin-top: 1.1875rem;
    display: block;
  }
}

.main__contact {
  display: flex;
  align-items: center;

  @include -lg {
    margin-top: 1rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
}

.contact-item {
  color: $koderia-gray-800;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-right: 2rem;

  @include -lg {
    margin-right: 1.5rem;
  }

  span {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    background: $koderia-gray-100;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
}

.contact-item:hover {
  text-decoration: underline;
}

.main__bio {
  font-size: 1rem;
  line-height: 1.75rem;
  max-width: 48rem;
  margin-bottom: 2rem;

  @include -lg {
    margin-top: 1rem;
    max-width: unset;
  }
}

.main-radar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @include -lg {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.ka-radar-wrapper {
  margin-top: -1rem;

  @media print {
    margin-top: -4rem;
    margin-right: 5rem;
    text-align: left;
    transform: scale(0.7);
  }
}

.main-radar__radar {
  position: relative;

  // @media print {
  //     display: none;
  // }

  @media only screen and (max-width: 22rem) {
    display: none;
  }

  @include -lg {
    margin-bottom: 2rem;
  }
}

.main-radar__radar_help-content {
  display: flex;
  align-items: center;
}

.main-radar__radar_help {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: $kod-green;
  padding: 0.125rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: white;
  text-decoration: none;
  border-radius: 0.125rem;
}

.main-radar__texchnologies {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  gap: 0.5rem;
  max-width: 32rem;
  padding-right: 2rem;

  @media print {
    max-width: 27rem;
  }

  @include -lg {
    max-width: 100%;
  }
}

.main-radar__texchnologies-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-radar__texchnologies-item-years {
    @media print {
      font-size: 10.5px;
    }
  }
}

.main__info {
  .main__technologies {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 0.5rem;

    .main__radar {
      display: none;
    }

    @include -lg {
      row-gap: 0.1rem;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fill, 1fr);
      margin-top: 0;
    }

    &.main__technologies--3 {
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);

      @include -lg {
        display: block;
      }
    }

    &.main__technologies--6 {
      grid-auto-flow: columns;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);

      @include -lg {
        display: block;
      }
    }

    .main__technologies-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;

      @include -lg {
        margin-top: 0.5rem;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.main__hr {
  border: none;
  border-top: 0.063rem solid $kod-hr;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.main__hr--large-space {
  @media print {
    margin-top: 3rem;
  }

  @include -lg {
    margin-bottom: 1.5rem;
  }
}

.section {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;

  &.section--first {
    padding-top: 2rem;
    margin-top: 0;

    & > h4 {
      margin-top: -0.6375rem;
    }
  }

  @include -lg {
    flex-direction: column;
    margin-top: 2rem;
  }
}

.section__title {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  width: 100%;
  max-width: 18.75rem;
  height: fit-content;
  break-inside: avoid-page;

  @include -lg {
    max-width: unset;
    margin-bottom: 2rem;
  }
}

.section__content {
  padding-top: 0.25rem;
  max-width: calc(100% - 18.75rem);

  @include -lg {
    max-width: unset;
  }

  & > div:not(:first-child) {
    margin-top: 3rem;
  }
}

.section__title--language {
  height: auto !important;
}

.section__content-item--language {
  & > div:not(:first-child) {
    margin-top: 0;
  }
}

.section__content-item:last-child {
  margin-bottom: 0;
}

.token-form {
  text-align: left;
  display: grid;
  gap: 0.5rem;
  width: 100%;
  max-width: 25rem;
  grid-auto-flow: row;
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}
</style>
