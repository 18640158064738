import { render, staticRenderFns } from "./ka-user-cv-show-page-certificate.vue?vue&type=template&id=f88a6c1a&scoped=true&"
import script from "./ka-user-cv-show-page-certificate.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-show-page-certificate.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-show-page-certificate.vue?vue&type=style&index=0&id=f88a6c1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88a6c1a",
  null
  
)

export default component.exports